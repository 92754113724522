.wrapper {
  width: 600px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  button {
    margin-top: 30px;
    width: 600px;
    height: 35px;
    background-color: #0570de;
    color: #fff;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  button:hover {
    cursor: pointer;
    background-color: #4492e0;
  }
}

@media screen and (max-width: 419px) {
  .wrapper {
    width: 370px;

    button {
      width: 370px;
    }
  }
}
