.error_section {
  padding-bottom: 86px;
  padding-top: 250px;
}
.error_form {
  text-align: center;

  p {
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 30px;
    color: #888;
  }

  h1 {
    font-size: 200px;
    font-weight: 700;
    color: #888;
    letter-spacing: 10px;
    line-height: 160px;
    margin: 0 0 83px;
  }

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 20px;
  }

  button {
    width: 200px;
    height: 40px;
    font-size: 15px;
  }
}
