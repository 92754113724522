.container {
  margin-inline: auto;
  max-width: 100%;
}
.text {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 23px;
  color: #333;
}
