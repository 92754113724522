.wrapper_1 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.wrapper_2 {
  padding: 30px;
  text-align: center;

  .go_home {
    color: #fff;
    background: #14a81c;
    border: none;
    padding: 10px 50px;
    margin: 30px 0;
    border-radius: 30px;
    text-transform: capitalize;
    box-shadow: 0 10px 16px 1px rgb(163, 163, 163);

    &:hover {
      background: #0b520f;
      box-shadow: 0 10px 16px 1px rgb(163, 163, 163);
    }
  }
}
h1 {
  font-family: "Kaushan Script", cursive;
  font-size: 4em;
  letter-spacing: 3px;
  color: #14a81c;
  margin: 0;
  margin-bottom: 20px;
  font-style: italic;
}
.wrapper_2 p {
  margin: 0;
  font-size: 1.3em;
  color: #aaa;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
}

.footer_like {
  margin-top: auto;
  background: rgb(178, 245, 163);
  padding: 6px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
}
.footer_like p {
  margin: 0;
  padding: 4px;
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 1px;
}
.footer_like p a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .wrapper_1 {
    height: 60vh;
  }

  .footer_like {
    margin-top: 10px;
    background: none;
  }
}

@media (min-width: 480px) {
  h1 {
    font-size: 4.5em;
  }
  .go_home {
    margin-bottom: 20px;
  }
}

@media (min-width: 600px) {
  .content {
    max-width: 1000px;
    margin: 0 auto;
  }
  .wrapper_1 {
    height: initial;
    max-width: 620px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 40px 8px rgb(163, 163, 163);
  }
}
