.circle_loading {
  width: 80px;
  height: 80px;
  border-radius: 99rem;
  position: relative;
  margin: 100px auto;
}

.circle_loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 4px solid transparent;
  border-right-color: #ff6bcb;
  border-bottom-color: #ffa400;
  animation: circleLoading 1s forwards infinite linear;
}

@keyframes circleLoading {
  to {
    transform: rotate(360deg);
  }
}
