.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .content {
        flex-grow: 1;
        margin-top: 80px;
    }
}
