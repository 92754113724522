.back_to_home {
  text-decoration: none;
  font-size: 11px;
  color: rgb(150, 150, 150);
  font-style: italic;
}

.back_to_home:hover {
  color: rgb(255, 56, 56);
}

@media screen and (max-width: 419px) {
  .wrapper {
    width: 93%;

    .input_email {
      width: 310px;
    }

    .input_password {
      width: 310px;
    }
    .button {
      width: 310px;
    }
  }
}
