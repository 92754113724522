@media screen and (max-width: 419px) {
  .wrapper {
    width: 93%;

    .input {
      width: 310px;
    }
    .button {
      width: 310px;
    }
  }
}
