.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 20px;
  .input {
    width: 300px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
  }

  button {
    width: 100px;
    height: 40px;
    font-size: 15px;
    margin-top: 20px;
  }

  .category {
    width: 300px;
    height: 40px;
    border-radius: 3px;
    border: 1px solid rgb(200, 200, 200);
    background-color: #fff;
    &:focus {
      border: none;
      outline: 1px solid #2e7cc4;
    }
  }
}

p {
  color: #d32f2f;
  margin-top: 5px;
}
