.wrapper {
  .input {
    width: 300px;
    height: 40px;
    margin: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
  }

  button {
    width: 100px;
    height: 40px;
    font-size: 15px;
  }
}
