.wrapper {
  margin-top: 10px;
}

.row {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .all_img {
    width: 45%;
    margin: 0px 20px 30px 0px;
    display: flex;
    justify-content: space-around;

    .img_product {
      width: 75%;
      max-height: 550px;
      object-fit: cover;
    }

    .color_product {
      width: 20%;

      .avt_product {
        margin-right: 10px;
        margin-bottom: 5px;
        width: 100%;
        height: 120px;
        object-fit: cover;
      }
    }
  }

  .info_product {
    width: 40%;

    .name_product {
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      margin: 10px 0 10px;
      text-transform: uppercase;

      .text_rating {
        color: #333;
        font-size: 10px;
        margin-left: 10px;
      }
    }

    .cost_product {
      color: #fa2634;
      width: 100%;
      border-bottom: 1px solid rgb(160, 160, 160);
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-top: 5px;

      .text_cost {
        color: #333;
        font-size: 13px;
        margin-right: 5px;
        text-decoration: underline;
      }
    }
  }

  .option_product {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .option_size {
      width: 45%;

      .tilte_size {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 22px;
        display: flex;

        .link_size {
          text-decoration: none;
          margin-left: 10px;
          color: #fa2634;
          font-style: italic;

          &:hover {
            color: #333;
            cursor: pointer;
          }
        }
      }
    }

    .option_amount {
      width: 45%;

      .name_title {
        font-size: 12px;
        margin-bottom: 10px;
      }

      .btn_buy {
        width: 100%;
        height: 40px;
        margin-top: 20px;
        background-color: #d32f2f;

        &:hover {
          background-color: #cc4c4c;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) and (min-width: 481px) {
  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    .all_img {
      width: 95%;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .info_product {
      width: 95%;
      margin: 0 auto;
      .option_size {
        width: 45%;
        option {
          width: 100%;
        }
      }
      .option_amount {
        width: 45%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-direction: column;
    width: 100%;
    .all_img {
      width: 95%;
      margin: 0 auto;
      .img_product {
        max-height: 350px;
      }
      .avt_product {
        max-height: 70px;
      }
    }
    .info_product {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 90%;
      .option_size {
        width: 45%;
      }
      .option_amount {
        width: 45%;
      }
    }
    .btn_buy {
      font-size: 8px;
    }
  }
}

.wrapper {
  display: flex;
}

.btn_style {
  width: 50px;
  height: 30px;
  border: none;
}

.btn_style:hover {
  cursor: pointer;
}

.text_style {
  width: 50px;
  font-size: 13px;
  padding: 10px 20px;
  margin: 0px 20px;
}
