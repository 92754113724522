.btnIcon {
  background-color: #ededed !important;
  color: #a04242 !important;
  border-radius: 5px !important;
  margin: 0px 0px 0px 5px !important;
  width: 32px;
  height: 32px;
}

.btnIcon:hover {
  border: #a04242 solid 1px;
}

.style_modal {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-image: url("https://topchiase24h.com/wp-content/uploads/2021/03/Hinh-nen-4K-1.png");
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  box-shadow: 24px;
  padding: 50px 100px;
}

.input_search {
  height: 40px;
  font-size: 15px;
  margin-top: 20px;
  padding-left: 20px;
  outline: none;
  border: 1px solid #b2b2b2;
  width: 600px;
  border-right-color: transparent;
}
.list_product_search {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 430px;
  overflow-y: scroll;
}
.list_product_search::-webkit-scrollbar {
  width: 5px;
}
.list_product_search::-webkit-scrollbar-track {
  background-color: #fafafa;
}
.list_product_search::-webkit-scrollbar-thumb {
  background-image: linear-gradient(-45deg, #929292);
  border-radius: 50px;
}

.loading {
  position: absolute;
  display: block;
  top: calc(50%);
  left: calc(100% - 150px);
  width: 20px !important;
  height: 20px !important;
}

.no-result {
  font-weight: 700;
  margin: 0;
}

.list_product_search span {
  color: #000;
}

.product_item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.product_item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.name {
  font-weight: bold;
  color: unset;
}
.name:hover {
  color: #b31f2a;
  cursor: pointer;
}
.price {
  font-weight: bold;
}

@media screen and (min-width: 420px) and (max-width: 1024px) {
  .style_modal {
    width: 650px;
    .input_search {
      width: 500px;
    }
  }
}

@media screen and (max-width: 419px) {
  .style_modal {
    width: 350px;
    .input_search {
      width: 340px;
    }
  }
}
